import { GetServerSideProps, InferGetServerSidePropsType } from "next";

import { BlockPage } from "@/components/BlockPage";
import { BlockPageProps } from "@/types/Block";
import { Experience, getExperience } from "@/utils/experience";
import { getIsProduction } from "@/utils/production";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeUndefined = (obj: unknown): any => {
  if (Array.isArray(obj)) {
    return obj.map(removeUndefined);
  } else if (typeof obj === "object" && obj !== null) {
    const objNext: Record<string, unknown> = {};

    Object.entries(obj).forEach(([key, value]) => {
      if (value !== undefined) {
        objNext[key] = removeUndefined(value);
      }
    });

    return objNext;
  }

  return obj;
};

export const getServerSideProps: GetServerSideProps<{
  content: BlockPageProps | null;
  experience: Experience | null;
  isProduction: boolean;
}> = async ({ req }) => {
  const experience = getExperience(req);
  const isProduction = getIsProduction();
  let content: { default: BlockPageProps } | undefined;

  try {
    content = await import(`../../profiles${experience?.profile}`);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Invalid profile path:", e);
    return {
      notFound: true
    };
  }

  if (
    !content ||
    (isProduction && !experience?.isProduction) ||
    (isProduction &&
      !!experience?.requiresReferer.length &&
      !experience?.requiresReferer.find((referer) => req.headers.referer?.includes(referer)))
  ) {
    return {
      props: {
        content: null,
        experience,
        isProduction
      }
    };
  }

  return {
    props: {
      content: removeUndefined(content.default),
      experience,
      isProduction
    }
  };
};

const NextJsBlockPage = ({
  content,
  experience
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return <BlockPage experience={experience} content={content} />;
};

export default NextJsBlockPage;
